<template>
  <div>
    <ejs-dialog
        ref="calendarWeatherPopup"
        :header="`VOC ${vocInfo.vocId ? '상세' : '등록'}`"
        width="600"
        :animationSettings="{ effect: 'None' }"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :cssClass="
          vocInfo.vocId
            ? $t('className.popup.productCodePopupModify')
            : $t('className.popup.productCodePopupAdd')
        "
        :close="closePopup"
    >
      <div class="window vocInfo">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">VOC 상세</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">작성자</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      작성일자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                id="writngDate"
                                                ref="writngDate"
                                                v-model="vocInfo.writngDate"
                                                type="body-data"
                                                format="YYYY-MM-DD"
                                                @keydown.native="formPreventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field wrterName">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      작성자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="wrterName"
                                                ref="wrterName"
                                                v-model="vocInfo.wrterName"
                                                placeholder="작성자명"
                                                @change="onCaddieNameChanged"
                                                @keydown.enter="wrterNameInputKeyEnter"
                                                @keydown.native="formPreventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                ref="memberPopupOpenButton"
                                                @click.native="openCaddieSelectPopup"
                                              >
                                                조회
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0102">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">고객정보</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-check">
                              <li>
                                <label>
                                  <input
                                    type="radio"
                                    v-model="isVisitorOrMember"
                                    :checked="isVisitorOrMember"
                                    value="VISITOR"
                                    name="isVisitorOrMember"
                                    @change="visitorOrMemberPopupOpen(true)"
                                  />
                                  <i></i>
                                  <div class="label">내장객</div>
                                </label>
                              </li>
                              <li>
                                <label>
                                  <input
                                    type="radio"
                                    v-model="isVisitorOrMember"
                                    :checked="isVisitorOrMember"
                                    value="MEMBER"
                                    name="isVisitorOrMember"
                                    @change="visitorOrMemberPopupOpen(true)"
                                  />
                                  <i></i>
                                  <div class="label">회원</div>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field visitName">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      내장객
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                                id="visitName"
                                                ref="visitName"
                                                v-model="vocInfo.visitName"
                                                placeholder="내장객명"
                                                @change="onMemberNameChanged"
                                                @keydown.enter="visitNameInputKeyEnter"
                                                @keydown.native="formPreventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                ref="memberPopupOpenButton"
                                                @click.native="visitorOrMemberPopupOpen()"
                                              >
                                                조회
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      회원구분
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ commonCodesGetComName('MEMBER_DIV', vocInfo.memberDiv) }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      시간
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ vocInfo.startTime }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      코스
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ commonCodesGetComName('COURSE_CODE', vocInfo.startCourse) }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      핸드폰
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ getFormattedPhoneNumber(vocInfo.hpNo) }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      회원번호
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ memberNoFormatter(vocInfo.memberNo) }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">고객의 소리</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      VOC부문
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="vocSection"
                                                ref="vocSection"
                                                v-model="vocInfo.vocSection"
                                                :dataSource="vocSectionOptions"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      VOC구분
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="vocCode"
                                                ref="vocCode"
                                                v-model="vocInfo.vocCode"
                                                :dataSource="vocCodeOptions"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      내용
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                                id="vocContents"
                                                ref="vocContents"
                                                placeholder="내용을 입력하세요."
                                                v-model="vocInfo.vocContents"
                                                :rows="6"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      조치구분
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="vocManagt"
                                                ref="vocManagt"
                                                v-model="vocInfo.vocManagt"
                                                :dataSource="vocManagtOptions"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                :enabled="!(!vocInfo.vocId || !vocInfo.isVocAdmin)"
                                                cssClass="body-data-dropdown"
                                                @select="onDropDownChange"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      조치일자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                                id="managtDate"
                                                ref="managtDate"
                                                v-model="vocInfo.managtDate"
                                                :disabled="!vocInfo.vocId || !vocInfo.isVocAdmin"
                                                type="body-data"
                                                format="YYYY-MM-DD"
                                                @keydown.native="formPreventEnterEvent"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      조치내용
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                                id="managtContents"
                                                ref="managtContents"
                                                placeholder="내용을 입력하세요."
                                                v-model="vocInfo.managtContents"
                                                :disabled="!vocInfo.vocId"
                                                :rows="6"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      등록자/일시
                                    </div>
                                    <div class="content">
                                      <ul class="row" v-if="vocInfo.insertId">
                                        <li class="item">
                                          {{ vocInfo.insertName }} / {{ vocInfo.insertDt }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field requestContent">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      수정자/일시
                                    </div>
                                    <div class="content">
                                      <ul class="row" v-if="vocInfo.updateId">
                                        <li class="item">
                                          {{ vocInfo.updateName }} / {{ vocInfo.updateDt }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button" style="float: left">
            <li class="reset">
              <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onClearClick(true)"
              >
              초기화
              </erp-button>
            </li>
          </ul>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :disabled="!isChangedVocInfo"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="add">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="addVocPopup.shortcuts.addVoc"
                  :shortcut="{key: 'F3'}"
                  @click.native="onClearClick(false)"
                  :ignore="isPopupOpened"
              >
                추가
              </erp-button>
            </li>
            <li v-if="vocInfo.vocId">
              <erp-button
                  button-div="DELETE"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  @click.native="onDeleteClick"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <caddie-select-popup
        ref="caddieSelectPopup"
        v-if="isCaddieSelectPopupOpen"
        @popupClosed="closeCaddieSelectPopup"
        @popupConfirmed="caddieSelectPopupConfirmed"
    />
    <find-visit-popup
        ref="findVisitPopup"
        v-if="isFindVisitPopupOpen"
        @popupClosed="onFindVisitPopupClosed"
    />
    <member-select-popup
      ref="memberSelectPopup"
      v-if="isMemberSelectPopupOpen"
      @popupClosed="onMemberSelectPopupClosed"
      @popupConfirmed="onMemberSelectPopupConfirm"
    />
  </div>
</template>

<script>
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {DATE_FORMAT_YYYY_MM_DD, getFormattedDate} from '@/utils/date';
import {commonCodesGetCommonCode, commonCodeGetComCodeDefaultValue, commonCodesGetComName} from '@/utils/commonCodes';
import {formPreventEnterEvent, validateFormRefs} from '@/utils/formUtil';
import InputText from '@/components/common/text/InputText';
import InputTextarea from '@/components/common/text/InputTextarea';
import InputDate from '@/components/common/datetime/InputDate';
import CaddieSelectPopup from '@/views/game-progress/popup/CaddieSelectPopup';
import FindVisitPopup from '@/views/common/FindVisitPopup';
import MemberSelectPopup from "@/views/common/MemberSelectPopup";
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {mapGetters} from 'vuex';
import { getFormattedPhoneNumber } from "@/utils/string";
import { memberNoFormatter } from "@/utils/formatter";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'AddVocPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputTextarea,
    InputDate,
    CaddieSelectPopup,
    FindVisitPopup,
    MemberSelectPopup,
    ErpButton,
  },
  data() {
    return {
      isVisitorOrMember: "VISITOR",
      oldVocInfo: null,
      vocInfo: {
        vocId: null,
        writngDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
        wrterName: null,
        visitName: null,
        vocSection: commonCodeGetComCodeDefaultValue('VOC_SECTION'),
        vocCode: null,
        vocContents: null,
        vocManagt:  commonCodeGetComCodeDefaultValue('VOC_MANAGT'),
        managtDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
        isVocAdmin: false,
      },
      vocSectionOptions: commonCodesGetCommonCode('VOC_SECTION', true),
      vocCodeOptions: commonCodesGetCommonCode('VOC_CODE', true),
      vocManagtOptions: commonCodesGetCommonCode('VOC_MANAGT', true),
      fields: {text: 'comName', value: 'comCode'},
      courseHoleInfos: [],
      isCaddieSelectPopupOpen: false,
      isFindVisitPopupOpen: false,
      isMemberSelectPopupOpen: false,
      validateRefList: {
        writngDate: {required: true},
        wrterName: {required: true},
        visitName: {required: true},
        vocSection: {required: true},
        vocCode: {required: true},
        vocContents: {required: true},
      },
    };
  },
  mounted() {
    this.vocManagtOptions.unshift({
      comCode: '',
      comName: '',
      codeAbrv: '',
      defaultFlag: true,
    });
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return (
          this.isCaddieSelectPopupOpen ||
          this.isFindVisitPopupOpen ||
          this.isMemberSelectPopupOpen
      );
    },
    isChangedVocInfo() {
      return JSON.stringify(this.vocInfo) !== JSON.stringify(this.oldVocInfo);
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
  methods: {
    getFormattedPhoneNumber,
    memberNoFormatter,
    onDropDownChange() {
      if(!this.vocInfo.managtDate){
          this.vocInfo.managtDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
      }
    },
    formPreventEnterEvent,
    validateFormRefs,
    commonCodesGetComName,
    async showPopup(vocId = null) {
      if (vocId) {
        this.vocInfo.vocId = vocId;
        await this.fetch();
      } else {
        this.vocInfo.wrterName = this.username;
      }
    },
    closePopup() {
      this.$emit('popupClosed');
    },
    async onCaddieNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === '') {
          this.vocInfo.caddieId = null;
          return;
        }
        await this.openCaddieSelectPopup();
      }
    },
    async onMemberNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === '') {
          this.vocInfo.membershipId = null;
          this.vocInfo.memberNo = null;
          this.vocInfo.memberDiv = null;
          return;
        }
        await this.visitorOrMemberPopupOpen();
      }
    },
    async wrterNameInputKeyEnter() {
      if (!this.vocInfo.wrterName || this.vocInfo.wrterName === '') {
        return;
      }
      await this.openCaddieSelectPopup();
    },
    async visitNameInputKeyEnter() {
      if (!this.vocInfo.visitName || this.vocInfo.visitName === '') {
        return;
      }
      await this.visitorOrMemberPopupOpen();
    },
    async openCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = true;
      await this.$nextTick();
      this.$refs.caddieSelectPopup.showPopup(this.vocInfo.wrterName, 'textField', null);
    },
    closeCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = false;
    },
    caddieSelectPopupConfirmed(data) {
      this.closeCaddieSelectPopup();
      if (data) {
        if (data.selectedRowData) {
          this.vocInfo.wrterName = data.selectedRowData.caddieName;
          this.vocInfo.caddieId = data.selectedRowData.caddieId;
        } else {
          this.vocInfo.caddieId = 0;
        }
      } else {
        this.vocInfo.caddieId = 0;
      }
    },
    async visitorOrMemberPopupOpen(reset = false) {
      if (this.isVisitorOrMember === "MEMBER") {
        this.openMemberSelectPopup(reset);
      } else {
        await this.openVisitFindPopup(reset);
      }
    },
    openMemberSelectPopup(reset) {
      this.isMemberSelectPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup({
          memberNameNo: reset? null: this.vocInfo.visitName,
        });
      });
    },
    async openVisitFindPopup(reset) {
      this.isFindVisitPopupOpen = true;
      await this.$nextTick();
      await this.$refs.findVisitPopup.showFindVisitPopup({
        bsnDate: getFormattedDate(this.vocInfo.writngDate),
        resveVisitName: reset? null: this.vocInfo.visitName,
        resveVisitFlag: false,
        isGetTeamData: true,
      });
    },
    onMemberSelectPopupConfirm(args) {
      this.isMemberSelectPopupOpen = false;
      if (args?.selectedRowData) {
        this.vocInfo.visitName = args.selectedRowData.memberName;
        this.vocInfo.visitId = 0;
        this.vocInfo.membershipId = args.selectedRowData.membershipId;
        this.vocInfo.memberNo = args.selectedRowData.memberNo;
        this.vocInfo.memberDiv = args.selectedRowData.memberDiv;
        this.vocInfo.startTime = "";
        this.vocInfo.startCourse = "";
        this.vocInfo.hpNo = args.selectedRowData.hpNo;
      }
    },
    onMemberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;

      this.vocInfo.visitId = 0;
      this.vocInfo.membershipId = '';
      this.vocInfo.memberNo = '';
      this.vocInfo.memberDiv = 'NOM';
      this.vocInfo.startTime = '';
      this.vocInfo.startCourse = '';
      this.vocInfo.hpNo = '';
    },
    onFindVisitPopupClosed(args) {
      this.isFindVisitPopupOpen = false;
      if (args && args.selectRowData && args.teamData) {
        this.vocInfo.visitName = args.selectRowData.visitName;
        this.vocInfo.visitId = args.selectRowData.visitId;
        this.vocInfo.membershipId = args.selectRowData.memberId;
        this.vocInfo.memberNo = args.selectRowData.memberNo;
        this.vocInfo.memberDiv = args.selectRowData.memberDiv;
        this.vocInfo.startTime = args.selectRowData.startTime;
        this.vocInfo.startCourse = args.selectRowData.startCourse;
        this.vocInfo.hpNo = args.selectRowData.hpNo;
      } else {
        this.vocInfo.visitId = 0;
        this.vocInfo.membershipId = '';
        this.vocInfo.memberNo = '';
        this.vocInfo.memberDiv = 'NOM';
        this.vocInfo.startTime = '';
        this.vocInfo.startCourse = '';
        this.vocInfo.hpNo = '';
      }
    },
    async onSaveClick() {
      if (!this.validate) {
        return;
      }
      if (!this.isChangedVocInfo) {
        this.errorToast(this.$t('main.validationMessage.noChanges'));
        return;
      }

      const params = {
        vocId: this.vocInfo.vocId,
        writngDate: this.vocInfo.writngDate,
        wrterName: this.vocInfo.wrterName,
        caddieId: this.vocInfo.caddieId,
        visitName: this.vocInfo.visitName,
        visitId: this.vocInfo.visitId,
        membershipId: this.vocInfo.membershipId,
        memberNo: this.vocInfo.memberNo,
        memberDiv: this.vocInfo.memberDiv || 'NOM',
        vocSection: this.vocInfo.vocSection,
        vocCode: this.vocInfo.vocCode,
        vocContents: this.vocInfo.vocContents,
        vocManagt: this.vocInfo.vocManagt,
        managtDate: this.vocInfo.managtDate || '',
        managtContents: this.vocInfo.managtContents,
        gcDiv: 'G',
      };

      try {
        this.vocInfo.vocId = await GolfErpAPI.putVocInfo(params);
        this.$refs.vocContents.focusIn();
        this.infoToast(this.$t('main.popupMessage.saved'));
        await this.fetch();
      } catch (error) {
        this.errorToast(error);
      }
    },
    async onClearClick(isAllClear = false) {
      this.vocInfo.vocId = null;
      if (isAllClear) {
        this.vocInfo.writngDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
        this.vocInfo.visitName = null;
        this.vocInfo.visitId = null;
        this.vocInfo.membershipId = null;
        this.vocInfo.memberNo = null;
        this.vocInfo.memberDiv = null;
        this.vocInfo.startTime = null;
        this.vocInfo.startCourse = null;
        this.vocInfo.hpNo = null;
      }
      this.vocInfo.vocSection = commonCodeGetComCodeDefaultValue('VOC_SECTION');
      this.vocInfo.vocCode = null;
      this.vocInfo.vocContents = '';
      this.vocInfo.vocManagt = null;
      this.vocInfo.writngDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
      this.vocInfo.managtContents = '';
      await this.$nextTick();
      isAllClear
          ? this.$refs.visitName.focusIn()
          : this.$refs.vocSection.focusIn();
    },
    async onDeleteClick() {
      if (!this.vocInfo.vocId || !(await this.confirm(this.$t('main.popupMessage.confirmDeleteShort')))) {
        return;
      }
      try {
        await GolfErpAPI.deleteVocInfo(this.vocInfo.vocId);
        this.infoToast(this.$t('main.popupMessage.deleted'));
        this.$emit('popupConfirmed');
      } catch (error) {
        this.errorToast(error);
      }
    },

    // API
    async fetch() {
      const vocInfo = await GolfErpAPI.fetchVocInfo(this.vocInfo.vocId);
      if (vocInfo.startTime) {
        vocInfo.startTime = `${vocInfo.startTime.substr(0, 2)}:${vocInfo.startTime.substr(2, 2)}`;
      }
      this.oldVocInfo = vocInfo;
      this.vocInfo = JSON.parse(JSON.stringify({
        ...vocInfo,
        managtDate: vocInfo.managtDate ? vocInfo.managtDate : null,
      }));
    },
  },
};
</script>
