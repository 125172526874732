<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.writngDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="dateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.vocSections }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                  v-model="searchConditions.vocSections"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.vocSections"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  :width="140"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.vocCodes }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                  v-model="searchConditions.vocCodes"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.vocCodes"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  :width="140"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.vocManagts }}
            </div>
            <ul class="content">
              <div class="item">
                <ejs-multiselect
                  v-model="searchConditions.vocManagts"
                  cssClass="lookup-condition-multiselect"
                  :dataSource="searchOptions.vocManagts"
                  :fields="commonCodeFields"
                  placeHolder="전체"
                  :width="220"
                />
              </div>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item" style="width: 80px">
                <ejs-dropdownlist
                  ref="searchNameDiv"
                  v-model="searchNameDiv"
                  :dataSource="searchOptions.searchNameDiv"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="body-data-dropdown"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchName"
                  v-model.trim="searchConditions.searchName"
                  @click.native="searchConditions.searchName = ''"
                  @keydown.enter="onViewVocStatus"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewVocStatus"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.orderDiv }}
            </div>
            <ul class="content">
              <li class="item" style="width: 80px">
                <ejs-dropdownlist
                  ref="orderDiv"
                  v-model="orderDiv"
                  :dataSource="searchOptions.orderDiv"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="body-data-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="isShowManagtContents"
                        v-model="isShowManagtContents"
                        value="true"
                      />
                      <i />
                      <div class="label">
                        {{ labels.isShowManagtContents }}
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <report-view
        ref="reportViewComponent"
        :isPopup="true"
      />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">VOC 목록</div>
              <div class="header-caption">[{{ count | numberWithCommas }}건]</div>
            </div>
            <div class="summary">
              <div class="item">
                <div class="content">
                  <div
                    v-for='(item, index) in searchOptions.vocCodes' :key='index'
                    :class="[
                      'item',
                      customFilters.vocCodes.includes(item.comCode) && 'active',
                    ]"
                    :style="{ cursor: customFilterStatus['vocCodes'][item.comCode] > 0 && 'pointer' }"
                    @click="onClickGridFiltering('vocCodes', item.comCode)"
                  >
                    <div
                      class="caption"
                      :style="{ color: labelColorValue(item.comCode) }"
                    >
                      {{ item.comName }}
                    </div>
                    <div class="count">
                      {{ customFilterStatus.vocCodes[item.comCode] | numberWithCommas }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="vocStatus.shortcuts.addVoc"
                      :shortcut="{key: 'F3'}"
                      :ignore="isPopupOpened"
                      @click.native="onAddClick"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click.native="onPrintClick"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onClickCustomFilterVocStatusPopupOpen"
                  >
                    필터 상세보기
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="vocStatusGrid"
                :allowEditing="false"
                :allowExcelExport="true"
                :allowPaging="true"
                :allowResizing="true"
                :provides="grid"
                :pageSettings="pageSettings"
                :columns="getColumns"
                :dataSource="vocStatusInfoList"
                @recordClick="vocStatusGridRecordClick"
                @headerCellInfo="vocStatusGridHeaderCellInfo"
                @queryCellInfo="vocStatusGridQueryCellInfo"
                @actionComplete="vocStatusGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <add-voc-popup
      v-if="isAddVocPopupOpen"
      ref="addVocPopup"
      @popupClosed="onCloseAddVocPopup"
      @popupConfirmed="onAddVocPopupConfirmed"
    />
    <custom-filter-voc-status-popup
      v-if="isCustomFilterVocStatusPopupOpen"
      ref="customFilterVocStatusPopup"
      :search-options="searchOptions"
      :custom-filters="customFilters"
      :custom-filter-status="customFilterStatus"
      @clickGridFiltering="onClickGridFiltering"
      @popupClosed="onCloseCustomFilterVocStatusPopup"
      @popupCleared="onCustomFilterVocStatusPopupCleared"
      @popupConfirmed="onCustomFilterVocStatusPopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}

/* 상단 집계 퍼블리싱 */
body .section-header {position: relative}
/*; font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif*/
body .section-header > .summary {display: flex; flex-direction: column; position: absolute; top: 5px; right: 400px}
body .section-header > .summary > .item:not(:last-child) {margin-bottom: 1rem}
body .section-header > .summary > .item > .title {margin-bottom: 1rem; font-weight: bold}
body .section-header > .summary > .item > .content {display: flex; height: 36px}
body .section-header > .summary > .item > .content > .item {display: flex; flex-direction: column; align-items: center; justify-content: flex-end; width: 70px; color: #333}
body .section-header > .summary > .item > .content > .item:not(:last-child) {border-right: 1px solid #e0e0e0}
body .section-header > .summary > .item > .content > .item > .caption {font-size: 12px}
body .section-header > .summary > .item > .content > .item > .count {color: #999; font-size: 13px}
body .section-header > .summary > .item > .content > .item.active > .count {margin-top: -1px; border-bottom: 1px solid #999}
</style>

<script>
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {
  commonCodesGetColorValue,
  commonCodesGetCommonCode, commonCodesGetSortNo
} from '@/utils/commonCodes';
import {
  SHORTCUT_KEYS,
  addShortcut,
  removeShortcut,
  keypressEventEqualsShortcut
} from '@/utils/KeyboardUtil';
import { orderBy as _orderBy } from "lodash";
import {
  ExcelExport,
  ForeignKey,
  Page,
  Resize
} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputText from '@/components/common/text/InputText';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import AddVocPopup from '@/views/voice-of-the-customer/popup/AddVocPopup';
import CustomFilterVocStatusPopup from '@/views/voice-of-the-customer/popup/CustomFilterVocStatusPopup';
import ReportView from '@/components/common/report/ReportView';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import GolfERPService from '@/service/GolfERPService';
import ErpButton from "@/components/button/ErpButton";

const SHORTCUT_FOCUS_SEARCH_VALUE = {
  key: 'VocStatus.shortcuts.focusVisitName',
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.Insert,
  },
};

export default {
  name: 'VocStatus',
  components: {
    ejsGridWrapper,
    InputText,
    InputDateRange,
    AddVocPopup,
    CustomFilterVocStatusPopup,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: { numberWithCommas },
  data() {
    return {
      count: 0,
      labels: {
        writngDate: '작성일자',
        vocSections: 'VOC부문',
        vocCodes: 'VOC구분',
        vocManagts: 'VOC조치',
        orderDiv: '정렬',
        isShowManagtContents: '조치내용 표시',
      },
      now: null,
      dateRange: {
        from: null,
        to: null,
      },
      searchNameDiv: 'wrterName',
      orderDiv: 'vocId',
      isShowManagtContents: false,
      vocStatusGridColumn: [
        {
          field: '_rid',
          isPrimaryKey: true,
          visible: false,
          type: 'number',
        },
        {
          field: 'vocId',
          visible: false,
          type: 'number',
        },
        {
          field: 'writngDate',
          headerText: '작성일자',
          width: 90,
          type: 'string',
          textAlign: 'center',
        },
        {
          field: 'visitName',
          headerText: '내장객명',
          width: 90,
          type: 'string',
        },
        {
          field: 'memberDiv',
          type: 'string',
          visible: false,
        },
        {
          field: 'memberDiv',
          headerText: '회원구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'MEMBER_DIV',
          width: 90,
          type: 'string',
        },
        {
          field: 'startTime',
          headerText: '시간',
          textAlign: 'center',
          width: 60,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: '코스',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 80,
          type: 'string',
        },
        {
          field: 'vocSection',
          headerText: 'VOC부문',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'VOC_SECTION',
          width: 80,
          type: 'string',
        },
        {
          field: 'vocCode',
          headerText: 'VOC구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'VOC_CODE',
          width: 80,
          type: 'string',
        },
        {
          field: 'vocCodeColorValue',
          type: 'string',
          visible: false,
        },
        {
          field: 'vocManagt',
          headerText: 'VOC조치',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'VOC_MANAGT',
          width: 80,
          type: 'string',
        },
        {
          field: 'vocManagtColorValue',
          type: 'string',
          visible: false,
        },
        {
          field: 'vocContents',
          headerText: 'VOC내용',
          width: 480,
          type: 'string',
        },
        {
          field: 'managtContents',
          headerText: '조치내용',
          width: 480,
          type: 'string',
        },
        {
          field: 'wrterName',
          headerText: '작성자',
          width: 90,
          type: 'string',
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          width: 130,
          type: 'string',
          textAlign: 'center',
        },
        {
          field: 'updateName',
          headerText: '수정자',
          width: 90,
          type: 'string',
        },
        {
          field: 'updateDt',
          headerText: '수정일시',
          width: 130,
          type: 'string',
          textAlign: 'center',
        },
      ],
      grid: [
        Resize,
        Page,
        ExcelExport,
        ForeignKey,
      ],
      pureVocStatusInfoList: [],
      vocStatusInfoList: [],
      customFilters: {
        vocSections: [],
        vocCodes: [],
        vocManagts: [],
      },
      customFilterStatus: {
        vocSections: {},
        vocCodes: {},
        vocManagts: {},
      },
      searchConditions: {
        vocSections: null,
        vocCodes: null,
        vocManagts: commonCodesGetCommonCode('VOC_MANAGT', true).filter(item => item.comCode !== 'DIS').map(item => item.comCode),
        searchName: null,
      },
      searchOptions: {
        vocSections: commonCodesGetCommonCode('VOC_SECTION', true),
        vocCodes: commonCodesGetCommonCode('VOC_CODE', true),
        vocManagts: commonCodesGetCommonCode('VOC_MANAGT', true),
        searchNameDiv: [
          {comName: '작성자명', comCode: 'wrterName'},
          {comName: '내장객명', comCode: 'visitName'},
          {comName: '내용', comCode: 'vocContents'},
        ],
        orderDiv: [
          {comName: '등록일시', comCode: 'vocId'},
          {comName: '내장객명', comCode: 'visitName'},
          {comName: '팀별', comCode: 'team'},
        ],
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      pageSettings: {pageSize: 50},
      isAddVocPopupOpen: false,
      isCustomFilterVocStatusPopupOpen: false
    };
  },
  async created() {
    await this.initialize();
    addShortcut(SHORTCUT_FOCUS_SEARCH_VALUE.key, SHORTCUT_FOCUS_SEARCH_VALUE.shortcut);
    window.addEventListener('keydown', this.onWindowKeypress, true);
  },
  async beforeDestroy() {
    window.removeEventListener('keydown', this.onWindowKeypress, true);
    removeShortcut(SHORTCUT_FOCUS_SEARCH_VALUE.key);
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return (
        this.isAddVocPopupOpen ||
        this.isCustomFilterVocStatusPopupOpen
      );
    },
    getColumns() {
      const column = JSON.parse(JSON.stringify(this.vocStatusGridColumn));
      return this.isShowManagtContents
        ? column
        : column.filter(item => item.field !== 'managtContents');
    },
  },
  methods: {
    async initialize() {
      const nowMoment = await GolfERPService.fetchNow();
      this.now = nowMoment.toDate();
      const from = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
      this.dateRange = {
        from,
        to,
      };
      await this.fetch();
    },
    async onViewVocStatus() {
      await this.fetch();
    },
    async onCloseAddVocPopup() {
      this.isAddVocPopupOpen = false;
      await this.fetch();
    },
    async onAddVocPopupConfirmed() {
      await this.onCloseAddVocPopup();
    },
    onCloseCustomFilterVocStatusPopup() {
      this.isCustomFilterVocStatusPopupOpen = false;
    },
    onCustomFilterVocStatusPopupCleared() {
      this.customFilters = {
        vocSections: [],
        vocCodes: [],
        vocManagts: [],
      };
      this.vocStatusInfoList = this.pureVocStatusInfoList;
    },
    onCustomFilterVocStatusPopupConfirmed() {
      this.onCloseCustomFilterVocStatusPopup();
    },
    async vocStatusGridRecordClick(args) {
      const {
        cellIndex,
        rowData: data,
      } = args;

      if (cellIndex === 4 || cellIndex === 14) {
        this.isAddVocPopupOpen = true;
        await this.$nextTick();
        console.log(data.vocId);
        this.$refs.addVocPopup.showPopup(data.vocId);
      }
    },
    vocStatusGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if ([
        'visitName',
        'vocContents',
      ].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    vocStatusGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      if ([
        'visitName',
        'vocContents',
      ].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
      if (data.isPartisionRow) {
        cell.style.borderTop = '1px solid #000';
      }
      if (data.isPartisionRowBottom) {
        cell.style.borderBottom = '1px solid #000';
      }
      if (field === 'vocCode') {
        cell.style.color = commonCodesGetColorValue('VOC_CODE', data.vocCode);
      } else if (field === 'vocManagt') {
        cell.style.color = commonCodesGetColorValue('VOC_MANAGT', data.vocManagt);
      } else if (field === 'vocContents') {
        cell.innerHTML = data.vocContents.split('\n').map(item => `<p>${item}</p>`).join('');
      }
    },
    vocStatusGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.vocStatusGrid
          ?.getGridBatchCount()
        || 0
      );
    },
    labelColorValue(comCode) {
      return commonCodesGetColorValue('VOC_CODE', comCode);
    },
    async onAddClick() {
      this.isAddVocPopupOpen = true;
      await this.$nextTick();
      this.$refs.addVocPopup.showPopup();
    },
    onPrintClick() {
      const options = [
        {
          field: 'memberDiv',
          comCode: 'MEMBER_DIV',
          format: 'comCode',
        },
        {
          field: 'vocSection',
          comCode: 'VOC_SECTION',
          format: 'comCode',
        },
        {
          field: 'vocCode',
          comCode: 'VOC_CODE',
          format: 'comCode',
        },
        {
          field: 'vocManagt',
          comCode: 'VOC_MANAGT',
          format: 'comCode',
        },
      ];

      const result = this.$refs.vocStatusGrid.getGridBatchData(options).map(item => {
        if (item.visitName) {
          item.visitName = `${item.visitName}<BR>(${item.memberDiv})`;
        }
        if (item.vocCode) {
          item.vocCode = `<C.${item.vocCodeColorValue}>${item.vocCode}</C>`;
        }
        if (item.vocManagt) {
          item.vocManagt = `<C.${item.vocManagtColorValue}>${item.vocManagt}</C>`;
        }
        return item;
      });
      if (result.length < 1) {
        return this.errorToast(this.$t('report.popupMessage.noData'));
      }

      const searchOptionsList = [
        {
          key: this.labels.writngDate,
          value:
            this.dateRange.from &&
            this.dateRange.to
              ? this.dateRange.from +
              ' ~ ' +
              this.dateRange.to
              : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter(item => !!item.value)
        .map(item => item.key + ': ' + item.value)
        .join(', ');

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onExcelClick() {
      this.$refs.vocStatusGrid.excelExport();
    },

    // API
    async fetch() {
      let beforeWritngDate = null, beforeVisitId = null;
      const data = (await GolfErpAPI.fetchVocList({
        dateFrom: this.dateRange.from,
        dateTo: this.dateRange.to,
        vocSections: this.searchConditions.vocSections,
        vocCodes: this.searchConditions.vocCodes,
        vocManagts: this.searchConditions.vocManagts,
        searchName: this.searchConditions.searchName,
        searchNameDiv: this.searchNameDiv,
        orderDiv: this.orderDiv,
      })).map(item => {
        if (beforeWritngDate !== item.writngDate) {
          item.isPartisionRow = !!beforeWritngDate;
          beforeWritngDate = item.writngDate;
        }
        if (this.orderDiv === 'team' && beforeVisitId !== item.visitId) {
          item.isPartisionRow = !!beforeVisitId;
          beforeVisitId = item.visitId;
        }
        if (this.orderDiv === 'team' && !item.visitId) {
          item.isPartisionRowBottom = !item.visitId;
          beforeVisitId = item.visitId;
        }
        if (item.startTime) {
          item.startTime = `${item.startTime.substr(0, 2)}:${item.startTime.substr(2, 2)}`;
        }
        if (item.vocCode) {
          item.vocCodeColorValue = commonCodesGetColorValue('VOC_CODE', item.vocCode);
        }
        if (item.vocManagt) {
          item.vocManagtColorValue = commonCodesGetColorValue('VOC_MANAGT', item.vocManagt);
        }
        return item;
      });
      this.pureVocStatusInfoList = this.vocStatusInfoList = _orderBy(data.map(item => ({
        ...item,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
        vocSectionSortNo: commonCodesGetSortNo("VOC_SECTION", item.vocSection),
      })), ["writngDate", "startTime", "courseCodeSortNo", "vocSectionSortNo"]);
      const groupCodeArray = [
        'vocSections',
        'vocCodes',
        'vocManagts'
      ];
      const comCodeArray = [
        'VOC_SECTION',
        'VOC_CODE',
        'VOC_MANAGT'
      ];
      const fieldArray = [
        'vocSection',
        'vocCode',
        'vocManagt'
      ];
      groupCodeArray?.forEach((groupCode, index) => {
        const comCodes = commonCodesGetCommonCode(comCodeArray[index], true)
          ?.map(item => item.comCode);
        comCodes?.forEach(comCode => {
          this.customFilterStatus[groupCode][comCode] = data
            ?.filter(item => item[fieldArray[index]] === comCode)
            ?.length;
        });
      });
      this.customFilters = {
        vocSections: [],
        vocCodes: [],
        vocManagts: [],
      };
      setTimeout(() => {
        if (!this.isPopupOpened) {
          this.$refs.searchName.focus();
        }
      }, 500);
    },
    onWindowKeypress(event) {
      if (keypressEventEqualsShortcut(event, SHORTCUT_FOCUS_SEARCH_VALUE.shortcut)) {
        if (!this.isPopupOpened) {
          this.$refs.searchName.focus();
        }
      }
    },
    onClickCustomFilterVocStatusPopupOpen() {
      if (this.isPopupOpened) {
        return;
      }
      this.isCustomFilterVocStatusPopupOpen = true;
    },
    onClickGridFiltering(groupCode = 'vocCodes', comCode = null) {
      if (this.customFilterStatus[groupCode][comCode] < 1) {
        return;
      }
      if (comCode) {
        const hasFilter = this.customFilters[groupCode]
          ?.includes(comCode);
        hasFilter
          ? this.customFilters[groupCode] = this.customFilters[groupCode]
            ?.filter(item => item !== comCode)
          : this.customFilters[groupCode].push(comCode);
      } else {
        const comCodes = this.searchOptions[groupCode]
          ?.map(item => item.comCode);
        this.customFilters[groupCode].length < comCodes.length
          ? this.customFilters[groupCode] = comCodes
          : this.customFilters[groupCode] = [];
      }
      // TODO : 추후 공통 유틸화 필요
      let vocStatusInfoList = [];
      if (
        this.customFilters.vocSections.length > 0 ||
        this.customFilters.vocCodes.length > 0 ||
        this.customFilters.vocManagts.length > 0
      ) {
        this.pureVocStatusInfoList
          ?.filter(item => this.customFilters.vocSections?.includes(item.vocSection))
          ?.forEach(item => vocStatusInfoList.push(item));
        this.pureVocStatusInfoList
          ?.filter(item => this.customFilters.vocCodes?.includes(item.vocCode))
          ?.forEach(item => vocStatusInfoList.push(item));
        this.pureVocStatusInfoList
          ?.filter(item => this.customFilters.vocManagts?.includes(item.vocManagt))
          ?.forEach(item => vocStatusInfoList.push(item));
      } else {
        vocStatusInfoList = this.pureVocStatusInfoList;
      }
      this.vocStatusInfoList = vocStatusInfoList;
    },
  },
};
</script>
