<template>
  <div>
    <ejs-dialog
      ref="customFilterVocStatusPopup"
      header="VOC현황 - 필터링"
      :width="700"
      :height="370"
      :isModal="true"
      :allowDragging="true"
      :enableResize="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      @close="closePopup"
    >
      <div class="window custom-filter-voc-status">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <article class="body-article">
                      <div class="summary">
                        <div
                          v-for="(field, fieldIndex) in fields" :key="fieldIndex"
                          class="item"
                        >
                          <div class="title">
                            {{ fieldNames[fieldIndex] }}
                          </div>
                          <div class="content">
                            <div
                              v-for='(item, index) in searchOptions[field]' :key='index'
                              :class="[
                                'item',
                                customFilters[field].includes(item.comCode) && 'active',
                              ]"
                              :style="{ cursor: customFilterStatus[field][item.comCode] > 0 && 'pointer' }"
                              @click="onClickGridFiltering(field, item.comCode)"
                            >
                              <div
                                class="caption"
                                :style="{ color: labelColorValue(fieldIndex, item.comCode) }"
                              >
                                {{ item.comName }}
                              </div>
                              <div class="count">
                                {{ customFilterStatus[field][item.comCode] | numberWithCommas }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button" style="float: left">
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onClearClick"
              >
                초기화
              </erp-button>
            </li>
          </ul>
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window.custom-filter-voc-status .summary {display: flex; flex-direction: column}
body .window.custom-filter-voc-status .summary > .item:not(:last-child) {margin-bottom: 1rem}
body .window.custom-filter-voc-status .summary > .item > .title {margin-bottom: 1rem; font-weight: bold}
body .window.custom-filter-voc-status .summary > .item > .content {display: flex; height: 36px}
body .window.custom-filter-voc-status .summary > .item > .content > .item {display: flex; flex-direction: column; align-items: center; justify-content: flex-end; width: 70px; color: #333}
body .window.custom-filter-voc-status .summary > .item > .content > .item:not(:last-child) {border-right: 1px solid #e0e0e0}
body .window.custom-filter-voc-status .summary > .item > .content > .item > .caption {font-size: 12px}
body .window.custom-filter-voc-status .summary > .item > .content > .item > .count {color: #999; font-size: 13px}
body .window.custom-filter-voc-status .summary > .item > .content > .item.active > .count {margin-top: -1px; border-bottom: 1px solid #999}
</style>

<script>
import { numberWithCommas } from '@/utils/number';
import { commonCodesGetColorValue } from '@/utils/commonCodes';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: 'CustomFilterVocStatusPopup',
  filters: { numberWithCommas },
  components: {
    ErpButton,
  },
  props: {
    searchOptions: {
      type: Object,
      default: () => null
    },
    customFilters: {
      type: Object,
      default: () => null
    },
    customFilterStatus: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      groupCodes: [
        'VOC_SECTION',
        'VOC_CODE',
        'VOC_MANAGT'
      ],
      fields: [
        'vocSections',
        'vocCodes',
        'vocManagts'
      ],
      fieldNames: [
        'VOC부문',
        'VOC구분',
        'VOC조치'
      ]
    };
  },
  methods: {
    closePopup() {
      this.$emit('popupClosed');
    },
    labelColorValue(fieldIndex, comCode) {
      return commonCodesGetColorValue(this.groupCodes[fieldIndex], comCode);
    },
    onClickGridFiltering(groupCode = 'vocCodes', comCode = null) {
      this.$emit('clickGridFiltering', groupCode, comCode);
    },
    async onClearClick() {
      this.$emit('popupCleared');
    },
    async onConfirmClick() {
      this.$emit('popupConfirmed');
    },
  },
};
</script>
